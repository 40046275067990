<template>
  <div style="padding-right: 20px">
    <el-form ref="dialogForm" :model="dialogForm"  label-width="100px">
      <el-form-item label="上传封面" prop="title">
        <el-upload ref="upload"
                   action="#"
                   accept="image/png,image/gif,image/jpg,image/jpeg"
                   list-type="picture-card"
                   :auto-upload="false"
                   :on-change="imgChange">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>


      <el-form-item label="标题" prop="title">
        <el-input v-model="subForm.caseName" />
      </el-form-item>

      <el-form-item label="选择分类" prop="departmentId" placeholder="选择分类" style="margin-bottom: 50px">
        <el-cascader-panel :options="options" clearable v-model="typeChoice"/>
      </el-form-item>

      <el-form-item label="正文" prop="description">
        <div id="div1" style="width: 100%" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>
<script>
// 引入 wangEditor
import wangEditor from 'wangeditor'
import {ElMessage} from "element-plus";
let formData = new FormData();
let editor = null
export default {
  name:"SuCaseAdd",
  data() {
    return {
      options:[
        {
          value: '产品设计',
          label: '产品设计',
          children: [
            {
              value: '医疗设备',
              label: '医疗设备',
            },
            {
              value: '仪器设备',
              label: '仪器设备',
            },
            {
              value: '消费电子',
              label: '消费电子',
            },
            {
              value: '交通出行',
              label: '交通出行',
            },
            {
              value: '工业设备',
              label: '工业设备',
            },
            {
              value: '机器人',
              label: '机器人',
            },]
        },
        {
          value: '研发工程',
          label: '研发工程',
          children: [
            {
              value: '结构设计',
              label: '结构设计',
            },
            {
              value: '自动化设计',
              label: '自动化设计',
            },]
        },
        {
          value: '传播设计',
          label: '传播设计',
          children: [
            {
              value: '品牌vi',
              label: '品牌vi',
            },
            {
              value: '电商视觉',
              label: '电商视觉',
            },
            {
              value: '网站/APP',
              label: '网站/APP',
            },
            {
              value: '产品包装',
              label: '产品包装',
            },
            {
              value: 'Logo设计',
              label: 'Logo设计',
            },
            {
              value: '画册设计',
              label: '画册设计',
            },
            {
              value: 'UI设计',
              label: 'UI设计',
            },]
        },
        {
          value: '空间设计',
          label: '空间设计',
          children: [
            {
              value: '商业空间',
              label: '商业空间',
            },
            {
              value: '厂房改造',
              label: '厂房改造',
            },
            {
              value: '会展陈列',
              label: '会展陈列',
            },
            {
              value: '空间导视',
              label: '空间导视',
            },]
        }
      ],
      typeChoice:[],

      editor: null,
      editorData: '',

      dialogForm:{
        priorityLevel:'',
      },
      subForm:{
        sid:'',
        caseName:'',
        typeFirst:'',
        typeSecond:'',
        details:'321321',
        pic:'',
      },
    }
  },
  mounted() {
    this.initEditor();
    this.subForm.sid = this.$route.query.sid;
  },
  methods: {
    initEditor() {
      if (editor)
        return;
      editor = new wangEditor('#div1')
      // 自定义菜单配置
      editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'undo', // 撤销
        'redo' // 重复
      ]
      //检测编辑器内容的变化
      editor.config.onchange = (html) => { // 编辑器里的内容
        this.subForm.details = html // 赋值给自己在data中定义的值
      }

      //上传图片
      //使用base64格式保存图片
      editor.config.uploadImgShowBase64 = false;
      // 配置 server 接口地址   与base64不共存
      editor.config.uploadImgServer = 'https://www.iloncloud.cn/caseDetailPicUpload';
      // 设置 headers
      editor.config.uploadHeaders = {
        'Content-Type': 'multipart/form-data'
      };
      // 配置自定义参数（举例）
      /*editor.config.uploadParams = {
        token: 'abcdefg',
        user: 'wangfupeng1988'
      };*/
      editor.config.uploadFileName = 'file';
      //限制图片大小
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      //限制图片类型
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png'];
      //图片上传数量限制
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      //等待接口的时间
      editor.config.uploadImgTimeout = 10 * 1000 //10秒
      //回调函数
      editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function(xhr) {
          // console.log(xhr)
          ElMessage({
            message: '图片上传中，请稍后',
            type: 'info',
          })
          // // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: '需要提示给用户的错误信息'
          // }
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function(xhr) {
          // console.log('success', xhr)
          ElMessage({
            message: '图片上传成功',
            type: 'success',
          })
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function(xhr, editor, resData) {
          // console.log('fail', resData)
          ElMessage({
            message: '图片上传成功，但插入失败',
            type: 'error',
          })
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          // console.log('error', xhr, resData)
          ElMessage({
            message: '图片上传失败',
            type: 'error',
          })
        },
        // 上传图片超时
        timeout: function(xhr) {
          // console.log('timeout')
          ElMessage({
            message: '图片上传超时，请检查网络连接',
            type: 'error',
          })
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function(insertImgFn, result) {
          // result 即服务端返回的接口
          // console.log('customInsert', result)

          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          insertImgFn(result.data[0])
        }
      }

      editor.create() // 创建使用富文本
    },
    //封面
    imgChange (files, fileList) {
      if (!(fileList[0].raw.type === 'image/png' || fileList[0].raw.type === 'image/gif' || fileList[0].raw.type === 'image/jpg' || fileList[0].raw.type === 'image/jpeg')) {
        this.$notify.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
      }
      let size = fileList[0].raw.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$notify.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
        return;
      }

      formData.append('pic',fileList[0].raw)
    },


    submit() {
      if (!formData.has("pic")){
        ElMessage({
          message: '未上传封面图',
          type: 'warning',
        })
        return;
      }

      if(this.subForm.caseName === ''){
        ElMessage({
          message: '标题不能为空',
          type: 'warning',
        })
        return;
      }

      if (this.typeChoice.length === 0){
        ElMessage({
          message: '请选择分类',
          type: 'warning',
        })
        return;
      }else {
        this.subForm.typeFirst = this.typeChoice[0];
        this.subForm.typeSecond = this.typeChoice[1];
      }

      if(this.subForm.details === ''){
        ElMessage({
          message: '内容不能为空',
          type: 'warning',
        })
        return;
      }

      console.log(this.subForm);
      //将数据转化为formData格式
      this.subForm.typeFirst = this.typeChoice[0];
      this.subForm.typeSecond = this.typeChoice[1];
      formData.append('caseName',this.subForm.caseName);
      formData.append('sid',this.subForm.sid);
      formData.append('typeFirst',this.subForm.typeFirst);
      formData.append('typeSecond',this.subForm.typeSecond);
      formData.append('details',this.subForm.details);

      ElMessage({
        message: '数据上传中，请稍后',
        type: 'info',
      })

      this.$http({
        method: 'post',
        url: '/admin/addCaseForSupplier',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.$notify({
          title: '上传成功',
          message: '',
          type: 'success'
        });
        this.editor = null;
        this.$router.push({path:'/SuAllCase',query: {sid: this.subForm.sid}});
      })
    }
  },

  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor = null
  },

}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/*编辑器高度*/
.w-e-text-container {
  height: 450px !important;
  z-index: 0;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>